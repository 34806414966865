<template lang="pug">
div
  v-btn(color='primary' data-test="c-item-submit" @click="submit" :loading="loading" :disabled="isArchived || isEditingDisabled") Save
  v-dialog(v-model="dialog" @click:outside="hideDialog")
    v-card
      v-card-title.headline Candidate with the same number
      .container.text-center.pb-5
        h1.pb-1 MERGE ALERT
        p(v-if="!isSuperVisor").red--text You are not allowed to merge. Please contact your supervisor
        h3(v-if="isSuperVisor").red--text This action is NOT reversible and WILL lead to DATA LOSS. Proceed only if this is the SAME person
        p(v-if="isSuperVisor") There is a candidate with the same number in the system. Are you sure you want to save?
      v-card-text
          v-row.double-column.pt-2
            v-col(v-if="sameCandidate && sameCandidate.candidateNumber" cols=6)
              h3.text-center.text-capitalize.red--text.pb-2 Delete
              v-text-field(label="Candidate Number" :value="sameCandidate.candidateNumber" readonly)
              v-text-field(label="Name" :value="sameCandidate.fullName" readonly)
              v-text-field(label="Birthday" :value="sameCandidate.candidateBirthday" readonly)
              v-text-field(label="Email" :value="sameCandidate.candidateEmail" readonly)
            v-col(v-if="model && model.candidateNumber" cols=6)
              h3.text-center.text-capitalize.green--text.pb-2 Keep
              v-text-field(label="Candidate Number" :value="model.candidateNumber" readonly)
              v-text-field(label="Name" :value="model.fullName" readonly)
              v-text-field(label="Birthday" :value="model.candidateBirthday" readonly)
              v-text-field(label="Email" :value="model.candidateEmail" readonly)
      v-card-actions
        slot(name="actions")
          v-spacer
          v-btn(color="error" text @click="update" :loading="loading" :disabled="!isSuperVisor") OVERWRITE AND MERGE
          v-btn(color="success" text @click="hideDialog") cancel
  app-dialog(v-model="showing" width=350 title="Save")
    template(#dialog-body)
      .check-bd__wrapper
        p Are you sure that date of birth is correct?
        app-input(
          :iconLink="'ui/calendar.svg'"
          :value="getModelBirthday"
          @input="setBirthdayDate($event)"
          placeholder="Birthday"
          :error="formErrors.hasError('candidateBirthday')"
          :error-messages="formErrors.fields.candidateBirthday"
          required-field
        )
    template(#dialog-footer)
      v-spacer
      app-button(color="#f4f4f4" hide-shadow @click.native="updateShowing(false)").unselectable.mr-2 Cancel
      app-button(color="primary" @click.native="submitBirthday").unselectable Ok
</template>

<script>
import Model from '../../core/candidates-reservation-model';
import showingMixin from '@/mixins/showing.mixin'
import permsMixin from '@/mixins/perms.mixin';
import errorsMixin from '@/mixins/errors.mixin';
import CandidateValidator from '../../core/models/validation/candidateValidator';
import { CBR_STATUS } from '../../core/candidates-const';
import featureFlagsMixin from '@/mixins/featureFlags.mixin';
import { COURSE_DAY_SIDEBAR_TABS } from '../../../course_day/core/courseDay-const';

export default {
  name: 'CandidateItemUpdate',

  mixins: [showingMixin, permsMixin, errorsMixin, featureFlagsMixin],

  inject: ['svc'],

  props: {
    model: Object,
    candidateModelNumber: Number,
    candidateNumberChanged: Boolean,
    updateRequests: Function,
    formErrors: Object
  },

  data: () => ({
    dialog: false,
    loading: false,
    sameCandidate: {
      candidateNumber: null,
      fullName: null,
      candidateBirthday: null,
      candidateEmail: null
    }
  }),

  computed: {
    isArchived() {
      if (!this.model) return true
      return this.model.status === CBR_STATUS.ARCHIVED
    },

    isEditingDisabled() {
      return this.isPastTab
    },

    isPastTab() { // Its for new course page
      return this.$route.query.tab_name === COURSE_DAY_SIDEBAR_TABS.PAST
    },

    getModelBirthday() {
      if (!this.model?.candidateBirthday) return ''
      return this.model?.candidateBirthday
    }
  },

  methods: {
    submit() {
      this.updateRequests()
      if (!new CandidateValidator(this.model, this.formErrors).validate()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return false;
      }
      if (!this.candidateNumberChanged) {
        this.update()
        return
      }
      if (this.candidateNumberChanged && this.model.candidateNumber) this.checkBirthDayDate()
    },

    checkBirthDayDate() {
      this.updateShowing(true)
    },

    setBirthdayDate(value) {
      this.model.candidateBirthday = value
    },

    submitBirthday() {
      if (!new CandidateValidator(this.model, this.formErrors).validate()) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return false;
      }
      this.updateShowing(false)
      this.checkOnSameCandidate()
    },

    async checkOnSameCandidate() {
      this.loading = true
      try {
        let res = await this.svc().findByNumber(this.model.candidateNumber)
        if (!res.id) {
          this.update()
          return
        }
        this.sameCandidate = new Model(res)
        this.dialog = true
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    update() {
      this.hideDialog()
      this.$emit('submit')
    },

    hideDialog() {
      this.dialog = false
      this.sameCandidate = null
    },

    merge() {
      this.dialog = false
      this.sameCandidate = null
    }
  },

  components: {
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appDialog: () => import('@/components/global/actions/BaseDialog.vue'),
    appInput: () => import('@/components/global/actions/BaseInput.vue')
  }
}
</script>

<style lang="scss" scoped>
.double-column {
  display: flex;
  margin: 0 auto;
  width: 100%;
}

.check-bd__wrapper {
  padding: 10px;
}
</style>
